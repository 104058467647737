import useOptimizeImageHook from "Hooks/useOptimizeImageHook";
import CountdownTimer from "./CountDown";

export default function ImageComponent({
  image,
  endDate,
  isHistory,
  isEligible,
  fallback = <div className="animate-pulse bg-white/10 w-full h-[10rem]" />,
  error = <div className="bg-red-50 text-red-500 p-2">Failed to load image</div>
}) {
  const { data: displayImage, isLoading, isError } = useOptimizeImageHook(image)

  if (!displayImage || isLoading) return fallback
  if (isError) return error
  return image?.endsWith('.webm') || image?.endsWith('.mp4') ? (
    <video className="w-full" controls>
      <source src={image} type={'video/webm'} />
    </video>
  ) : (
    <div
      className="relative overflow-hidden rounded-nft"
      style={{
        backgroundImage: `url("${displayImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      {!isEligible && <CountdownTimer deadlineUnix={endDate} isHistory={isHistory} />}
      <img
        src={displayImage}
        className="absolute w-full h-full opacity-0 inset-0"
        alt={name}
      />
      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
        width="800px"
        height="auto"
        className="w-full"
        alt={name}
      />
    </div>
  )
}
