import React from 'react';

const NFTSkeletonLoader = () => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="relative w-full">
          <div className="block relative h-full rounded-xl border border-white/10 overflow-hidden">
            {/* Image skeleton */}
            <div className="relative">
              <div className="w-full pt-[100%] bg-gray-800 animate-pulse" />

              {/* Status badge skeleton */}
              <div className="absolute top-6 right-2">
                <div className="w-20 h-8 bg-gray-800 rounded-lg animate-pulse" />
              </div>
            </div>

            {/* Title area skeleton */}
            <div className="p-3 bg-gray-900">
              <div className="w-24 h-5 bg-gray-800 rounded animate-pulse mb-1" />
              <div className="w-16 h-4 bg-gray-800 rounded animate-pulse" />
            </div>

            {/* Footer area skeleton */}
            <div className="p-3 border-t border-white/10 bg-gray-900">
              <div className="flex items-center justify-between">
                <div className="w-16 h-5 bg-gray-800 rounded animate-pulse" />
                <div className="w-16 h-8 bg-gray-800 rounded animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NFTSkeletonLoader;