import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { PawnShopDescription } from '../../pages/comingSoon'

import { X } from 'lucide-react';
import { NavButton } from '../Buttons/navButton';

function isWithinNext7Days() {
  const now = new Date();
  const sevenDaysFromNow = new Date(now.getTime() + (7 * 24 * 60 * 60 * 1000));
  const currentDate = new Date();

  return currentDate <= sevenDaysFromNow;
}
export default function PawnShopDialog() {
  let [isOpen, setIsOpen] = useState(isWithinNext7Days());

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div id='PawnShopDialog' className=''>
      <div className="inset-0 flex items-center justify-start mb-10">
        <NavButton
          isHistory={false}
          text={"Learn More"}
          disabled={false}
          onClick={openModal}
          textStyle={`text-md`}
        />

      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-2xl bg-black/50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto pt-[72px]">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="flex flex-col items-center relative w-full max-h-[calc(100vh-72px-32px)] max-w-screen-lg transform overflow-auto rounded-2xl bg-gray-900/50 p-6 text-left align-middle shadow-xl transition-all gap-4">
                  {/* Close button */}
                  <div className='w-full flex justify-end h-[40px]'>
                    <button
                      onClick={closeModal}
                      className="p-2 rounded-full hover:bg-gray-800 transition-colors"
                    >
                      <X className="w-5 h-5 text-gray-400" />
                    </button>
                  </div>
                  <PawnShopDescription />
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
