import { get, post } from 'aws-amplify/api';

function isCreateApp(obj) {
  try {
    return obj && 'id' in obj
  } catch (error) {

  }
}
async function processBankApiResponse(response) {
  try {
    const result = await response.body.json();

    if (result?.success) {
      return {
        success: true,
        data: result.data
      };
    }

    return {
      success: false,
      error: {
        message: result.error?.message || 'Request failed'
      }
    };
  } catch (e) {
    return {
      success: false,
      error: {
        message: e.message || 'Failed to process response'
      }
    };
  }
}

async function processLoansApiResponse(response) {
  try {
    const result = await response.body.json();
    if (isCreateApp(result)) {
      return parseInt(result.id)
    }

    return {
      success: false,
      error: {
        message: result.error?.message || 'Request failed'
      }
    };
  } catch (e) {
    return {
      success: false,
      error: {
        message: e.message || 'Failed to process response'
      }
    }
  }
}
async function processResponse(response, api = 'bankApi') {
  switch (api) {
    case 'bankApi':
      return processBankApiResponse(response)
    case 'loans':
      return processLoansApiResponse(response)
    default:
      return processBankApiResponse(response)
  }
}
export async function getLoanAssets(address) {
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/getLoanAssets',
      options: {
        body: { public_key: address }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function getLoans(address) {
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/getLoans',
      options: {
        body: { public_key: address }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function getFreeLoans() {
  try {
    const restOperation = get({
      apiName: 'loans',
      path: '/getLoan',  // Updated to match the API Gateway path
    });
    const response = await restOperation.response;
    const resp = await processResponse(response, 'loans')
    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function lastLoan(address, id) {
  if (!address) return
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/getLastLoan',
      options: {
        body: { public_key: address, nftId: id }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function lendNft(loanLength, nftId, lendTransactions, appId) {

  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/lend',
      options: {
        body: { loanLength, nftId, lendTransactions, appId }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function payLoan(pay_transactions) {
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/pay',
      options: {
        body: { pay_transactions }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function payOverdue(overdue_transactions) {
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/overdue',
      options: {
        body: { overdue_transactions }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
export async function partialPay(partial_pay_transactions) {
  try {
    const restOperation = post({
      apiName: 'bankApi',
      path: '/partialPay',
      options: {
        body: { partial_pay_transactions }
      }

    });
    const response = await restOperation.response;
    const resp = await processResponse(response)

    return resp
  } catch (e) {
    console.log('GET call failed: ', e);
    throw e
  }
}
