// src/queries/provider.js
import { useQueryClient } from '@tanstack/react-query'
import { createContext, useCallback, useContext, useEffect } from 'react'
import useOverdueLoans from '../hooks/useOverDueLoans';
import { queryKeys } from '../services/api/queries';
import { useWallet } from '@txnlab/use-wallet';
import { client } from '../services/api/graphql';
import { onCreateLOAN, onUpdateLOAN } from '../amplify/graphql/subscriptions';

const LoanQueryContext = createContext(undefined);

// Central provider for handling query interactions and transaction updates
export function LoanQueryProvider({ children }) {
  const queryClient = useQueryClient()
  const { } = useOverdueLoans()
  const { activeAddress } = useWallet()
  // Function to handle all necessary query updates after transactions
  const invalidateQueries = useCallback(async () => {
    if (!activeAddress) {
      console.log('No active address, skipping invalidation');
      return;
    }

    const analyticsKey = queryKeys.loanAnalytics.all;
    const accountKey = queryKeys.accountAssets.byAddress(activeAddress);

    // Log current query states
    // console.log('Current analytics data:', queryClient.getQueryData(analyticsKey));
    // console.log('Current account data:', queryClient.getQueryData(accountKey));

    try {
      // Invalidate both queries simultaneously
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: analyticsKey,
          refetchType: 'active',
          exact: true
        }),
        queryClient.invalidateQueries({
          queryKey: accountKey,
          refetchType: 'active',
          exact: true
        })
      ]);

      // Force immediate refetch
      await Promise.all([
        queryClient.fetchQuery({
          queryKey: analyticsKey,
          staleTime: 0
        }),
        queryClient.fetchQuery({
          queryKey: accountKey,
          staleTime: 0
        })
      ]);

    } catch (error) {
      console.error('Query invalidation failed:', error);
      throw error;
    }
  }, [queryClient, activeAddress]);

  // Expose transaction handler to components
  const handleTransaction = useCallback(async (txnType) => {
    console.log(`Handling ${txnType} transaction`);

    try {
      // Wait for blockchain to settle
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Track invalidation attempt
      console.log('Starting query invalidation');
      await invalidateQueries();
      console.log('Query invalidation completed');

    } catch (error) {
      console.error('Transaction handler failed:', error);
      // Retry once after a longer delay
      try {
        await new Promise(resolve => setTimeout(resolve, 5000));
        await invalidateQueries();
      } catch (retryError) {
        console.error('Retry failed:', retryError);
        throw retryError;
      }
    }
  }, [invalidateQueries]);

  // Subscription setup with error handling
  useEffect(() => {
    if (!client) {
      console.warn('GraphQL client not initialized');
      return;
    }

    let createSub, updateSub;

    try {
      createSub = client
        .graphql({ query: onCreateLOAN })
        .subscribe({
          next: (data) => {
            // console.log('Create subscription triggered:', data);
            // handleTransaction('CREATE').catch(console.error);
          },
          error: (error) => {
            console.error('Create subscription error:', error);
          }
        });

      updateSub = client
        .graphql({ query: onUpdateLOAN })
        .subscribe({
          next: (data) => {
            console.log('Update subscription triggered:', data);
            // handleTransaction('UPDATE').catch(console.error);
          },
          error: (error) => {
            console.error('Update subscription error:', error);
          }
        });

    } catch (error) {
      console.error('Subscription setup failed:', error);
    }

    return () => {
      createSub?.unsubscribe();
      updateSub?.unsubscribe();
    };
  }, [handleTransaction]);
  return (
    <LoanQueryContext.Provider value={{ handleTransaction }}>
      {children}
    </LoanQueryContext.Provider>
  )
}

// Hook for components to access query functions
export function useLoanQueries() {
  const context = useContext(LoanQueryContext)
  if (!context) {
    throw new Error('useLoanQueries must be used within LoanQueryProvider')
  }
  return context
}