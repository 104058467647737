import { Link } from 'react-router-dom';

export default function NavLink({ text, link }) {
  return <Link to={link}
    className="bg-dark-gradient hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient p-2 rounded-tab text-xs">
    {text}
  </Link>
}
export function NavButton({ text, onClick, isHistory, textStyle = `text-xs`, ...rest }) {
  const label = isHistory ? 'Paid' : text
  return <button onClick={onClick}
    {...rest}
    className={`${isHistory ? "bg-dark-brand2" : "bg-dark-gradient hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient"} p-2 rounded-tab ${textStyle}`}>
    {label}
  </button>
}