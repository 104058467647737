import React, { Suspense } from 'react';

export const NFTCardSkeleton = () => {
  return (
    <div className="relative w-full animate-pulse h-[10rem]">
      <div className="block relative h-full rounded-nft border border-dark-border bg-white/5">
        {/* Image Skeleton */}
        <div className="relative overflow-hidden rounded-nft bg-white/5" style={{ paddingTop: '100%' }}>
          <div className="absolute inset-0" />
        </div>

        {/* Title Area Skeleton */}
        <span className="block p-3 bg-dark-table rounded-t-big mtn">
          <div className="h-4 bg-white/5 rounded w-3/4 mb-2"></div>
          <div className="h-3 bg-white/5 rounded w-1/2"></div>
        </span>

        {/* Bottom Area Skeleton */}
        <div className="p-3 bg-dark-table">
          <div className="h-8 bg-white/5 rounded w-full"></div>
        </div>
      </div>
    </div>
  );
};

const SkeletonGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <NFTCardSkeleton />
      <NFTCardSkeleton />
      <NFTCardSkeleton />
    </div>
  );
};

// Example NFT Card component that would normally load the data
const NFTCard = ({ image, name, id, amount, loanState, loanamount, rank }) => {
  return (
    <div className={`relative w-full hover:[transform:scale(1.04)] transition-transform duration-200`}>
      <div className="snow-cap"></div>
      <div className="block relative h-full rounded-nft border border-dark-border text-gray-900">
        {rank && (
          <span className="rarity">
            <small className="text-xs">
              <i className="fa-regular fa-crown"></i> {rank}
            </small>
          </span>
        )}

        {image?.endsWith('.webm') || image?.endsWith('.mp4') ? (
          <video className="w-full" controls>
            <source src={image} type={'video/webm'} />
          </video>
        ) : (
          <div
            className="relative overflow-hidden rounded-nft"
            style={{
              backgroundImage: `url("${image}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          >
            <img
              src={image}
              className="absolute w-full h-full opacity-0 inset-0"
              alt={name}
            />
            <img
              src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
              width="800px"
              height="auto"
              className="w-full"
              alt={name}
            />
          </div>
        )}

        <span className="block p-3 bg-dark-table rounded-t-big mtn">
          <p className="mb-0 truncate text-white">
            {name} {amount > 1 && (`x${amount}`)}
          </p>
          <small className="text-gray-600">{id}</small>
        </span>

        {loanState === 'eligible' ? (
          <LoanNft />
        ) : loanState === 'history' ? (
          <HistoryNft loanAmount={loanamount} />
        ) : (
          <PayNft loanAmount={loanamount} loanState={loanState} />
        )}
      </div>
    </div>
  );
};

// Example usage with Suspense
const NFTGallery = () => {
  return (
    <Suspense fallback={<SkeletonGrid />}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* Your NFT cards would go here */}
        <NFTCard /* props */ />
        <NFTCard /* props */ />
        <NFTCard /* props */ />
      </div>
    </Suspense>
  );
};
export const NftSkeletons = Array.from({ length: 3 }, (_, index) => (
  <NFTCardSkeleton key={index} />
));
export default SkeletonGrid;