export const config = {
  aws_project_region: process.env.REACT_APP_project_region,
  aws_cognito_identity_pool_id: process.env.REACT_APP_cognito_identity_pool_id,
  aws_cognito_region: process.env.REACT_APP_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_user_pools_id,
  aws_user_pools_web_client_id: process.env.REACT_APP_user_pools_web_client_id,
  "aws_cloud_logic_custom": [
    {
      name: process.env.REACT_APP_bank_name,
      endpoint: process.env.REACT_APP_bank_endpoint,
      region: process.env.REACT_APP_bank_region,
    },
    {
      name: process.env.REACT_APP_loans_api_name,
      endpoint: process.env.REACT_APP_loans_api_endpoint,
      region: process.env.REACT_APP_bank_region,
    }
  ],
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_appsync_graphqlEndpoint,
  aws_appsync_region: process.env.REACT_APP_appsync_region
};

export const deadline = new Date('2024-11-19T00:00:00'); // Assuming next Tuesday is March 19th

export const CREATOR = process.env.REACT_APP_CREATOR
